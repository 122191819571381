import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';
import { css } from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import VerticalProductCard from '../VerticalProductCard/VerticalProductCard';
import { getYieldRates } from '../../domains/savingsYieldRate/getYieldRate';

import ReasonsBelongingPurpleContainer from './styles/ReasonsBelongingPurple';

const ReasonsBelongingPurple = ({
  creditsParams, savingsParams, mainHeadingTag, show,
}) => {
  const [yieldRate, setYieldRate] = useState(null);

  const fetchYieldRateInfo = async () => {
    try {
      const yieldRateInfo = await getYieldRates();
      setYieldRate(yieldRateInfo);
    } catch (error) {
      setYieldRate(null);
    }
  };

  useEffect(() => {
    fetchYieldRateInfo();
  }, []);
  const { formatMessage } = useIntl();
  const specificColor = 'default';
  let mainHeadingLabel = '';
  if (mainHeadingTag) {
    mainHeadingLabel = useMemo(() => formatMessage({ id: mainHeadingTag }));
  }

  return show ? (
    <ReasonsBelongingPurpleContainer>
      <Typography
        css={css`span {color: ${specificColor.startsWith('#') ? specificColor : nuDSColor('primary', specificColor)};}`}
        className="subtitle1"
        variant="heading2"
        dangerouslySetInnerHTML={{ __html: mainHeadingLabel }}
      />
      <VerticalProductCard {...savingsParams} rate={yieldRate?.cajitas} />
      <VerticalProductCard {...creditsParams} />

    </ReasonsBelongingPurpleContainer>
  ) : null;
};

ReasonsBelongingPurple.defaultProps = {
  show: true,
};

ReasonsBelongingPurple.propTypes = {
  creditsParams: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    buttonTag: PropTypes.string.isRequired,
    buttonUrlTag: PropTypes.string.isRequired,
    buttonVariant: PropTypes.string.isRequired,
    headingColor: PropTypes.string.isRequired,
    headingTag: PropTypes.string.isRequired,
    subtitleColor: PropTypes.string.isRequired,
    subtitleTag: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    textTag: PropTypes.string.isRequired,
  }).isRequired,
  mainHeadingTag: PropTypes.string.isRequired,
  savingsParams: PropTypes.shape({
    backgroundColor: PropTypes.string.isRequired,
    buttonTag: PropTypes.string.isRequired,
    buttonUrlTag: PropTypes.string.isRequired,
    buttonVariant: PropTypes.string.isRequired,
    headingColor: PropTypes.string.isRequired,
    headingTag: PropTypes.string.isRequired,
    subtitleColor: PropTypes.string.isRequired,
    subtitleFallback: PropTypes.string.isRequired,
    subtitleTag: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    textTag: PropTypes.string.isRequired,

  }).isRequired,
  show: PropTypes.bool,
};

export default ReasonsBelongingPurple;
