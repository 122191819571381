import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import { css } from 'styled-components';

import { toYieldRateStringFromFloat } from '../../domains/savingsYieldRate/getYieldRate';

import VerticalProductCardContainer from './styles/VerticalProductCardContainer';

const VerticalProductCard = ({
  headingTag, subtitleTag, textTag, buttonTag, subtitleFallbackTag, buttonVariant, buttonUrlTag,
  backgroundColor, headingColor, rate, subtitleColor, textColor, buttonColor,
}) => {
  const { formatMessage } = useIntl();
  const headingLabel = useMemo(() => formatMessage({ id: headingTag }));
  const subtitleLabel = useMemo(() => formatMessage({ id: subtitleTag },
    { yield: toYieldRateStringFromFloat(rate?.yield_rate) }));
  const subtitleFallbackLabel = subtitleFallbackTag != null ? useMemo(() => formatMessage({ id: subtitleFallbackTag })) : '';
  const textLabel = useMemo(() => formatMessage({ id: textTag }));
  const buttonUrl = useMemo(() => formatMessage({ id: buttonUrlTag }));
  return (
    <VerticalProductCardContainer
      backgroundColor={backgroundColor}
      headingColor={headingColor}
      subtitleColor={subtitleColor}
      textColor={textColor}
    >
      <div className="card">
        <div className="paragraphs">
          <Typography
            variant="heading2"
            className="heading"
            dangerouslySetInnerHTML={{ __html: headingLabel }}
          />
          {subtitleFallbackTag == null && (
          <Typography
            className="subtitle"
            dangerouslySetInnerHTML={{ __html: subtitleLabel }}
          />
          )}
          {subtitleFallbackTag != null && rate != null && (
          <Typography
            className="subtitle"
            dangerouslySetInnerHTML={{ __html: subtitleLabel }}
          />
          )}
          {subtitleFallbackTag != null && rate == null && (
          <Typography
            className="subtitle"
            dangerouslySetInnerHTML={{ __html: subtitleFallbackLabel }}
          />
          )}
          <Typography
            className="paragraph"
            variant="paragraph1"
            dangerouslySetInnerHTML={{ __html: textLabel }}
          />
        </div>

        <Link href={buttonUrl} css={css`width: inherit`}>
          <Button
            extended
            id="show-product-btn"
            css={css`color: ${buttonColor}`}
            styleVariant={buttonVariant}
            variant="contained"
            intlKey={buttonTag}
            iconProps={{ name: 'arrow-right' }}
          />
        </Link>

      </div>
    </VerticalProductCardContainer>
  );
};

VerticalProductCard.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonTag: PropTypes.string.isRequired,
  buttonUrlTag: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string.isRequired,
  headingColor: PropTypes.string.isRequired,
  headingTag: PropTypes.string.isRequired,
  rate: PropTypes.shape({
    maturity: PropTypes.number.isRequired,
    since: {
      year: PropTypes.number.isRequired,
      month: PropTypes.string.isRequired,
      day: PropTypes.number.isRequired,
    },
    yield_rate: PropTypes.number.isRequired,
  }),
  subtitleColor: PropTypes.string.isRequired,
  subtitleFallbackTag: PropTypes.string,
  subtitleTag: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  textTag: PropTypes.string.isRequired,
};

VerticalProductCard.defaultProps = {
  subtitleFallbackTag: null,
  rate: null,
};

export default VerticalProductCard;
