import { ProductCardType } from '../../../components/ProductCard/ProductCard';
import { savingsPerksOverrides, creditPerksOverrides, perksOverrides } from '../styles/Overrides';

const ComponentDefaultProps = {
  fixedBanner: {
    imageSrc: 'savings/vigilado-xl.png',
    label: 'SAVINGS.FIXED_BANNER.LABEL',
  },
  hero: {
    title: 'MULTI_PRODUCT_HOME.HERO.TITLE',
    subtitle: 'MULTI_PRODUCT_HOME.HERO.SUBTITLE',
    alt: 'MULTI_PRODUCT_HOME.HERO.IMAGE.ALT',
    heroImages: {
      xs: 'multi-product-home/hero/hero-xs-1x.jpg',
      md: 'multi-product-home/hero/hero-md-1x.jpg',
      lg: 'multi-product-home/hero/hero-lg-1x.jpg',
    },
    form: {
      label: 'MULTI_PRODUCT_HOME.HERO.FORM.LABEL',
      placeholder: 'MULTI_PRODUCT_HOME.HERO.FORM.PLACEHOLDER',
      btnLabel: 'MULTI_PRODUCT_HOME.HERO.FORM.BUTTON_LABEL',
      btnStyleVariant: 'black',
      validateDisabled: false,
    },
    specificColor: 'default',
  },
  nuMessage: {
    title: 'CREDIT_CARD.MESSAGE.TEXT',
    spanColor: '#820AD1',
  },
  disclaimer: {
    message: 'MULTI_PRODUCT_HOME.HERO.BLUR.LABEL',
    show: false,
  },
  savingsProduct: {
    sectionColor: '#F2EEEE',
    productImages: {
      xs: 'multi-product-home/products/savings/savings-without-logos-xs-2x.jpg',
      md: 'multi-product-home/products/savings/savings-without-logos-md-2x.jpg',
      lg: 'multi-product-home/products/savings/savings-without-logos-lg-2x.jpg',
    },
    header1: 'MULTI_PRODUCT_HOME.PRODUCTS.SAVINGS.HEADER1',
    header2: 'MULTI_PRODUCT_HOME.PRODUCTS.SAVINGS.HEADER2',
    text: 'CUCUTA.PRODUCTS.SAVINGS.TEXT',
    productCardType: ProductCardType.IMAGE_RIGHT,
    btnLabel: 'MULTI_PRODUCT_HOME.PRODUCTS.SAVINGS.BUTTON',
    btnHref: 'MULTI_PRODUCT.MENU.SAVINGS.PATH',
    btnColor: 'black',
    color: '#A376FF',
    textColor: 'white',
  },
  savingsPerks: {
    alt: 'MULTI_PRODUCT_HOME.SAVINGS_VALUE_PROPOSAL.ALT',
    backGroungColor: '#F2EEEE',
    heroImages: {
      xs: 'multi-product-home/products/savings/perks/savings-perks.gif',
      md: 'multi-product-home/products/savings/perks/savings-perks.gif',
      lg: 'multi-product-home/products/savings/perks/savings-perks.gif',
    },
    title: 'MULTI_PRODUCT_HOME.SAVINGS_VALUE_PROPOSAL.TITLE',
    titleColor: 'rgba(0,0,0,0.64)',
    gridLargeOrder: '"valueProps divider picture"',
    hasDivider: true,
    styleOverride: savingsPerksOverrides,
  },
  creditsProduct: {
    productImages: {
      xs: 'multi-product-home/products/credits/creditsprd-xs.jpg',
      md: 'multi-product-home/products/credits/creditsprd-md.jpg',
      lg: 'multi-product-home/products/credits/creditsprd-lg.jpg',
    },
    header1: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.HEADER1',
    header2: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.HEADER2',
    text: 'CUCUTA.PRODUCTS.CREDIT-CARD.TEXT',
    productCardType: ProductCardType.IMAGE_LEFT,
    btnLabel: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.BUTTON',
    btnHref: 'MULTI_PRODUCT.MENU.CREDIT_CARD.PATH',
    btnColor: '#820AD1',
    color: '#820AD1',
    textColor: 'white',
  },
  creditsPerks: {
    alt: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.PERKS.ALT',
    backGroungColor: 'white.default',
    heroImages: {
      xs: 'multi-product-home/products/credits/perks/credit-card-perks-xs-1x.gif',
      md: 'multi-product-home/products/credits/perks/credit-card-perks-xs-1x.gif',
      lg: 'multi-product-home/products/credits/perks/credit-card-perks-xs-1x.gif',
    },
    imageSize: {
      xs: 150,
      md: 300,
      lg: 300,
    },
    title: 'MULTI_PRODUCT_HOME.PRODUCTS.CREDITS.PERKS.TITLE',
    titleColor: 'primary.default',
    gridLargeOrder: '"valueProps divider picture"',
    hasDivider: true,
    styleOverride: creditPerksOverrides,
  },
  reasons: {
    creditsParams: {
      headingTag: 'MULTI_PRODUCT_HOME.REASONS.CREDITS.HEADER',
      subtitleTag: 'MULTI_PRODUCT_HOME.REASONS.CREDITS.SUBTITLE',
      textTag: 'CUCUTA.REASONS.CREDITS.TEXT',
      buttonTag: 'MULTI_PRODUCT_HOME.REASONS.CREDITS.BUTTON',
      buttonUrlTag: 'MULTI_PRODUCT.MENU.CREDIT_CARD.PATH',
      buttonColor: 'primary',
      buttonVariant: 'white',
      backgroundColor: '#820AD1',
      headingColor: '#FFFFFF',
      subtitleColor: '#FFFFFFE0',
      textColor: '#FFFFFF',
    },
    savingsParams: {
      headingTag: 'MULTI_PRODUCT_HOME.REASONS.SAVINGS.HEADER',
      subtitleTag: 'MULTI_PRODUCT_HOME.REASONS.SAVINGS.SUBTITLE',
      subtitleFallbackTag: 'MULTI_PRODUCT_HOME.REASONS.SAVINGS.SUBTITLE.FALLBACK',
      textTag: 'CUCUTA.REASONS.SAVINGS.TEXT',
      buttonTag: 'MULTI_PRODUCT_HOME.REASONS.SAVINGS.BUTTON',
      buttonUrlTag: 'MULTI_PRODUCT.MENU.SAVINGS.PATH',
      buttonColor: 'black',
      buttonVariant: 'white',
      backgroundColor: '#C7AAFF',
      headingColor: '#000000F5',
      subtitleColor: '#000000A3',
      textColor: '#000000A3',
    },
    mainHeadingTag: 'MULTI_PRODUCT_HOME.REASONS.MAIN.HEADER',
  },
  perks: {
    alt: 'MULTI_PRODUCT_HOME.PERKS.ALT',
    perksImages: {
      xs: 'multi-product-home/perks/perks-xs-2x.jpg',
      md: 'multi-product-home/perks/perks-md-2x.jpg',
      lg: 'multi-product-home/perks/perks-lg-2x.jpg',
    },
    firstTitle: 'MULTI_PRODUCT_HOME.PERKS.FIRST.TITLE',
    firstBody: 'MULTI_PRODUCT_HOME.PERKS.FIRST.BODY',
    secondTitle: 'MULTI_PRODUCT_HOME.PERKS.SECOND.TITLE',
    secondBody: 'MULTI_PRODUCT_HOME.PERKS.SECOND.BODY',
    thirdTitle: 'MULTI_PRODUCT_HOME.PERKS.THIRD.TITLE',
    thirdBody: 'MULTI_PRODUCT_HOME.PERKS.THIRD.BODY',
    violetTitle: 'MULTI_PRODUCT_HOME.PERKS.VIOLET.TITLE',
    backgroundColor: '#F2EEEE',
    textVariant: 'heading4',
    styleOverride: perksOverrides,
  },
  values: {
    title: 'MULTI_PRODUCT_HOME.VALUES.TITLE',
    firstBody: 'MULTI_PRODUCT_HOME.VALUES.DESCRIPTION.FIRST',
    firstTitle: 'MULTI_PRODUCT_HOME.VALUES.SUBTITLE.FIRST',
    secondBody: 'MULTI_PRODUCT_HOME.VALUES.DESCRIPTION.SECOND',
    secondTitle: 'MULTI_PRODUCT_HOME.VALUES.SUBTITLE.SECOND',
    firstButton: 'MULTI_PRODUCT_HOME.VALUES.BUTTON.FIRST',
    secondButton: 'MULTI_PRODUCT_HOME.VALUES.BUTTON.SECOND',
    firstProductLink: 'MULTI_PRODUCT_HOME.VALUES.LINK.FIRST',
    secondProductLink: 'MULTI_PRODUCT_HOME.VALUES.LINK.SECOND',
  },
  press: {
    links: [{
      summary: 'MULTI_PRODUCT_HOME.PRESS.SUMMARY.FIRST',
      linkText: 'MULTI_PRODUCT_HOME.PRESS.LINK_TEXT.FIRST',
      linkUrl: 'MULTI_PRODUCT_HOME.PRESS.LINK_URL.FIRST',
    }, {
      summary: 'MULTI_PRODUCT_HOME.PRESS.SUMMARY.SECOND',
      linkText: 'MULTI_PRODUCT_HOME.PRESS.LINK_TEXT.SECOND',
      linkUrl: 'MULTI_PRODUCT_HOME.PRESS.LINK_URL.SECOND',
    }, {
      summary: 'MULTI_PRODUCT_HOME.PRESS.SUMMARY.THIRD',
      linkText: 'MULTI_PRODUCT_HOME.PRESS.LINK_TEXT.THIRD',
      linkUrl: 'MULTI_PRODUCT_HOME.PRESS.LINK_URL.THIRD',
    },
    ],
    title: 'MULTI_PRODUCT_HOME.PRESS.TITLE',
  },
  blog: {
    alt: 'MULTI_PRODUCT_HOME.BLOG.IMG.ALT',
    blogImages: {
      xs: 'multi-product-home/blog/blog-xs-1x.jpg',
      md: 'multi-product-home/blog/blog-lg-1x.jpg',
      lg: 'multi-product-home/blog/blog-lg-1x.jpg',
    },
    title: 'MULTI_PRODUCT_HOME.BLOG.TITLE',
  },
  faq: {
    title: 'MULTI_PRODUCT_HOME.FAQ.TITLE',
    questions: [
      {
        question: 'CUCUTA.FAQ.QUESTION.FIRST',
        answer: 'CUCUTA.FAQ.QUESTION.ANSWER.FIRST',
        initiallyOpen: true,
      },
      {
        question: 'CUCUTA.FAQ.QUESTION.SECOND',
        answer: 'CUCUTA.FAQ.QUESTION.ANSWER.SECOND',
        initiallyOpen: true,
      },
      {
        question: 'CUCUTA.FAQ.QUESTION.THIRD',
        answer: 'CUCUTA.FAQ.QUESTION.ANSWER.THIRD',
        initiallyOpen: true,
      },
      {
        question: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.FOURTH',
        answer: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.ANSWER.FOURTH',
        href: 'MULTI_PRODUCT_HOME.FAQ.QUESTION.HREF.FOURTH',
        initiallyOpen: true,
      },
    ],
  },
  feedbacks: [{
    description: 'MULTI_PRODUCT_HOME.FEEDBACKS.1.DESCRIPTION',
    owner: 'MULTI_PRODUCT_HOME.FEEDBACKS.1.OWNER',
    location: 'MULTI_PRODUCT_HOME.FEEDBACKS.1.LOCATION',
  },
  {
    description: 'MULTI_PRODUCT_HOME.FEEDBACKS.2.DESCRIPTION',
    owner: 'MULTI_PRODUCT_HOME.FEEDBACKS.2.OWNER',
    location: 'MULTI_PRODUCT_HOME.FEEDBACKS.2.LOCATION',
  },
  {
    description: 'MULTI_PRODUCT_HOME.FEEDBACKS.3.DESCRIPTION',
    owner: 'MULTI_PRODUCT_HOME.FEEDBACKS.3.OWNER',
    location: 'MULTI_PRODUCT_HOME.FEEDBACKS.3.LOCATION',
  },
  {
    description: 'MULTI_PRODUCT_HOME.FEEDBACKS.4.DESCRIPTION',
    owner: 'MULTI_PRODUCT_HOME.FEEDBACKS.4.OWNER',
    location: 'MULTI_PRODUCT_HOME.FEEDBACKS.4.LOCATION',
  }],
  mgm: {
    title: 'MGM.FORM.V2.HEAD.TITLE',
    subtitle: 'MGM.FORM.HEAD.TITLE',
    subtitle2: 'MGM.SUBTITLE.TEXT',
    alt: 'MGM.HERO.IMAGE.ALT',
    heroImages: {
      xs: 'mgm/mgm-xs-2x.jpg',
      md: 'mgm/mgm-md-2x.jpg',
      lg: 'mgm/mgm-lg-2x.jpg',
    },
    alt2: 'MGM.HERO.V2.IMAGE.ALT',
    heroImages2: {
      xs: 'mgm/mgm-lg-2x-v2.jpg',
      md: 'mgm/mgm-lg-2x-v2.jpg',
      lg: 'mgm/mgm-lg-2x-v2.jpg',
    },
    form: {
      label: 'MULTI_PRODUCT_HOME.HERO.FORM.LABEL',
      placeholder: 'MULTI_PRODUCT_HOME.HERO.FORM.PLACEHOLDER',
      btnLabel: 'MULTI_PRODUCT_HOME.HERO.FORM.BUTTON_LABEL',
      validateDisabled: false,
    },
    form2: {
      label: 'MGM.FORM.V2.FORM_LABEL',
      placeholder: 'MULTI_PRODUCT_HOME.HERO.FORM.PLACEHOLDER',
      btnLabel: 'MGM.FORM.V2.BUTTON_LABEL',
      validateDisabled: false,
    },
    formTickets: {
      label: 'MGM.FORM.TICKETS.FORM_LABEL',
      placeholder: 'MULTI_PRODUCT_HOME.HERO.FORM.PLACEHOLDER',
      btnLabel: 'MGM.FORM.V2.BUTTON_LABEL',
      validateDisabled: false,
      btnStyleVariant: 'primary',
    },
    tickets: [
      {
        ticketId: 'ticket_1',
        ticketTitle: 'MGM.HERO.TICKETS.TICKET_01.TITLE',
        ticketFallbackTitle: 'MGM.HERO.TICKETS.TICKET_01.FALLBACK_TITLE',
        ticketImageAlt: 'MGM.HERO.TICKETS.TICKET_01.IMAGE.ALT',
        ticketImages: {
          xs: 'mgm/ticket-01-01.png',
          md: 'mgm/ticket-01-01.png',
          lg: 'mgm/ticket-01-01.png',
        },
      },
      {
        ticketId: 'ticket_2',
        ticketTitle: 'MGM.HERO.TICKETS.TICKET_02.TITLE',
        ticketFallbackTitle: 'MGM.HERO.TICKETS.TICKET_02.FALLBACK_TITLE',
        ticketImageAlt: 'MGM.HERO.TICKETS.TICKET_02.IMAGE.ALT',
        ticketImages: {
          xs: 'mgm/ticket-01-02.png',
          md: 'mgm/ticket-01-02.png',
          lg: 'mgm/ticket-01-02.png',
        },
      },
      {
        ticketId: 'ticket_3',
        ticketTitle: 'MGM.HERO.TICKETS.TICKET_03.TITLE',
        ticketFallbackTitle: 'MGM.HERO.TICKETS.TICKET_03.FALLBACK_TITLE',
        ticketImageAlt: 'MGM.HERO.TICKETS.TICKET_03.IMAGE.ALT',
        ticketImages: {
          xs: 'mgm/ticket-01-03.png',
          md: 'mgm/ticket-01-03.png',
          lg: 'mgm/ticket-01-03.png',
        },
      },
      {
        ticketId: 'ticket_4',
        ticketTitle: 'MGM.HERO.TICKETS.TICKET_04.TITLE',
        ticketFallbackTitle: 'MGM.HERO.TICKETS.TICKET_04.FALLBACK_TITLE',
        ticketImageAlt: 'MGM.HERO.TICKETS.TICKET_04.IMAGE.ALT',
        ticketImages: {
          xs: 'mgm/ticket-02-01.png',
          md: 'mgm/ticket-02-01.png',
          lg: 'mgm/ticket-02-01.png',
        },
      },
      {
        ticketId: 'ticket_5',
        ticketTitle: 'MGM.HERO.TICKETS.TICKET_05.TITLE',
        ticketFallbackTitle: 'MGM.HERO.TICKETS.TICKET_05.FALLBACK_TITLE',
        ticketImageAlt: 'MGM.HERO.TICKETS.TICKET_05.IMAGE.ALT',
        ticketImages: {
          xs: 'mgm/ticket-02-02.png',
          md: 'mgm/ticket-02-02.png',
          lg: 'mgm/ticket-02-02.png',
        },
      },
      {
        ticketId: 'ticket_6',
        ticketTitle: 'MGM.HERO.TICKETS.TICKET_06.TITLE',
        ticketFallbackTitle: 'MGM.HERO.TICKETS.TICKET_06.FALLBACK_TITLE',
        ticketImageAlt: 'MGM.HERO.TICKETS.TICKET_06.IMAGE.ALT',
        ticketImages: {
          xs: 'mgm/ticket-02-03.png',
          md: 'mgm/ticket-02-03.png',
          lg: 'mgm/ticket-02-03.png',
        },
      },
    ],
    specificColor: 'default',
    productsSectionBackground: 'white',
    productCardType: ProductCardType.IMAGE_RIGHT,
  },
};

export default ComponentDefaultProps;

export const CulturalHackTreatmentAProps = {
  ...ComponentDefaultProps,
  hero: {
    ...ComponentDefaultProps.hero,
    title: 'MULTI_PRODUCT_HOME.HERO.CULTURAL_HACK.TREATMENT_A.TITLE',
    subtitle: 'MULTI_PRODUCT_HOME.HERO.CULTURAL_HACK.SUBTITLE',
    alt: 'MULTI_PRODUCT_HOME.HERO.CULTURAL_HACK.IMAGE.ALT',
    heroImages: {
      xs: 'multi-product-home/hero/hero-cultural-hack-xs-2x.jpg',
      md: 'multi-product-home/hero/hero-cultural-hack-xs-2x.jpg',
      lg: 'multi-product-home/hero/hero-cultural-hack-xs-2x.jpg',
    },
    form: {
      ...ComponentDefaultProps.hero.form,
      label: 'MULTI_PRODUCT_HOME.HERO.CULTURAL_HACK.FORM.LABEL',
    },
  },
};

export const CulturalHackTreatmentBProps = {
  ...ComponentDefaultProps,
  hero: {
    ...ComponentDefaultProps.hero,
    title: 'MULTI_PRODUCT_HOME.HERO.CULTURAL_HACK.TREATMENT_B.TITLE',
    subtitle: 'MULTI_PRODUCT_HOME.HERO.CULTURAL_HACK.SUBTITLE',
    alt: 'MULTI_PRODUCT_HOME.HERO.CULTURAL_HACK.IMAGE.ALT',
    heroImages: {
      xs: 'multi-product-home/hero/hero-cultural-hack-xs-2x.jpg',
      md: 'multi-product-home/hero/hero-cultural-hack-xs-2x.jpg',
      lg: 'multi-product-home/hero/hero-cultural-hack-xs-2x.jpg',
    },
    form: {
      ...ComponentDefaultProps.hero.form,
      label: 'MULTI_PRODUCT_HOME.HERO.CULTURAL_HACK.FORM.LABEL',
    },
  },
};

export const RedebanTreatmentProps = {
  ...ComponentDefaultProps,
  hero: {
    ...ComponentDefaultProps.hero,
    title: 'MULTI_PRODUCT_HOME.HERO.REDEBAN.TITLE',
    subtitle: 'MULTI_PRODUCT_HOME.HERO.REDEBAN.SUBTITLE',
  },
  savingsProduct: {
    ...ComponentDefaultProps.savingsProduct,
    header2: 'MULTI_PRODUCT_HOME.PRODUCTS.SAVINGS.REDEBAN.HEADER2',
  },
  savingsPerks: {
    ...ComponentDefaultProps.savingsPerks,
    title: 'MULTI_PRODUCT_HOME.SAVINGS_VALUE_PROPOSAL.REDEBAN.TITLE',
  },
};
